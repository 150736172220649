var filterParams = {
    comparator: function(filterLocalDateAtMidnight, cellValue) {
      var dateAsString = cellValue;
      if (dateAsString == null) return -1;
      var dateParts = dateAsString.split('/');
      var cellDate = new Date(
        Number(dateParts[2]),
        Number(dateParts[1]) - 1,
        Number(dateParts[0]),
      );
      if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
        return 0;
      }
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      }
      if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
    },
    browserDatePicker: true,
    minValidYear: 2000
};

// function dateComparator(date1, date2) {

//   var date1Number = monthToComparableNumber(date1);
//   var date2Number = monthToComparableNumber(date2);
//   if (date1Number === null && date2Number === null) {
//     return 0;
//   }
//   if (date1Number === null) {
//     return -1;
//   }
//   if (date2Number === null) {
//     return 1;
//   }
//   return date1Number - date2Number;
// }


// var monthToComparableNumber = function monthToComparableNumber(date) {
//   if (date === undefined || date === null || date.length !== 10) {
//     return null;
//   }
//   var yearNumber = date.substring(6, 10);
//   var monthNumber = date.substring(3, 5);
//   var dayNumber = date.substring(0, 2);
//   var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
//   return result;
// };

// var filterParams = {
//     // provide comparator function
//     comparator: (filterLocalDateAtMidnight, cellValue) => {
//         const dateAsString = cellValue;

//         if (dateAsString == null) {
//             return 0;
//         }

//         // In the example application, dates are stored as dd/mm/yyyy
//         // We create a Date object for comparison against the filter date
//         const dateParts = dateAsString.split('/');
//         const day = Number(dateParts[2]);
//         const month = Number(dateParts[1]) - 1;
//         const year = Number(dateParts[0]);
//         // const hours = Number(timeParts.substring(0,1));
//         // const seconds = Number(timeParts.substring(3,4));
//         const hours = 14;
//         const seconds = 3;
//         const cellDate = new Date(year, month, day, hours, seconds);

//         // Now that both parameters are Date objects, we can compare
//         if (cellDate < filterLocalDateAtMidnight) {
//             return -1;
//         } else if (cellDate > filterLocalDateAtMidnight) {
//             return 1;
//         }
//         return 0;
//     }
// }

export default filterParams;