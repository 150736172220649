import axios from 'axios'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        disruptions: [],
        loading: true,
    },
    actions: {
        loadData(context, {url, rowData}){
            axios.get(url, {
                // headers: {
                //   'Access-Control-Allow-Origin' : '*',
                //   "Content-Type": "application/json"
                // },
                // withCredentials: false,
                validateStatus: function (status) {
                    return status >= 200 && status <= 304; // default
                },
                // auth: {
                //   username: 'itest',
                //   password: 'itest2019'
                // }
            })
            .then((response) => {
                // eslint-disable-next-line no-var
                var listData = []
                // eslint-disable-next-line no-var
                for (var i in response.data){
                  // return this.disruptions[i]["actual start date"].substr(0,10)
                  // eslint-disable-next-line no-var
                  var arrData = {
                    'debut': response.data[i]['actual start date'],
                    'fin': response.data[i]['actual end date'],
                    'source': response.data[i]['source'],
                    'localisation': response.data[i]['localisation'],
                    'type': response.data[i]['disruption type'],
                    'agence': response.data[i]['agency'],
                    'message': response.data[i]['message']
                  }
                  listData.push(arrData)
                }
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                rowData = listData
                console.log(rowData)
                context.commit('updateDisruptions', response.data)
                context.commit('changeLoadingState', false)
            })
            .catch(error => {console.log(error)})
        },
    },
    mutations: {
        updateDisruptions(state, disruptions) {
            state.disruptions = disruptions
        },
        changeLoadingState(state, loading) {
            state.loading = loading
        }
    }
})