<template>
  <div class="container g-content">
    <div class="content" v-if="!detail">
      <div class="img-group">
        <!-- <b-button variant="secondary" class="side-btn" href="http://localhost:8080/table" target="_blank"><font-awesome-icon icon="bars"></font-awesome-icon></b-button> -->
        <img class="img" src="../../static/img/logo-nextalert.png" />
      </div>
      <div class="d-text">
        <p class="p-text"> CD 45 - Perturbations sur les routes départementales</p>
      </div>
      <div class="btn-content">
        <!--<a :href="config.viaproLink" class="viapro" target="_blank">Aller sur ViaPro</a> -->
        <!-- <font-awesome-icon icon="user-circle" style="height: 40px; width: 50px; float: right;"/> -->
        <!-- <b-button class="btn" variant="danger"><p class="btn-icon"><b-icon-plus-circle-fill></b-icon-plus-circle-fill><span class="btn-span">Nouvelle perturbation</span></p></b-button> -->
      </div>
    </div>
    <div class="content" v-else>
      <div class="img-group">
        <!-- <b-button variant="secondary" class="side-btn"><font-awesome-icon icon="bars"></font-awesome-icon></b-button> -->
        <img class="img" src="../../static/img/logo-nextalert.png" />
      </div>
      <div :class="getTitleClass">
        <p class="p-text2">Détail de la perturbation « {{ getTitle }} »</p>
      </div>
      <div class="btn-content2">
       <!-- <a :href="config.viaproLink" class="viapro" target="_blank">Aller sur Viapro</a> -->
        <!-- <font-awesome-icon icon="user-circle" style="height: 40px; width: 50px; float: right;"/> -->
      </div>
    </div>
  </div>
</template>

<script>
import config from "../config";
export default {
  data() {
    return {
        // config: config
        config: window.viaproLink
    };
  },
  computed: {
    detail() {
      if (this.$router.currentRoute.name === "Detail") return true;
      return false;
    },
    getTitle() {
      if (localStorage.getItem("params")) {
        var arrData = JSON.parse(localStorage.getItem("params"));
        var title = arrData["message"].split("à")
        console.log(title[0].length)
        return title[0];
      }
      return null;
    },
    getTitleClass() {
      if (this.getTitle.length <= 15) {
        return 't_pos1'
      }
      else return 'd-text2'
    }
  },
  methods: {
    linkTo() {
      window.open("http://localhost:8080/table", "_blank");
    },
  },
};
</script>

<style lang="scss">
.g-content {
  text-align: center;
  position: relative;
  margin: auto;
  .content {
    margin-top: 50px;
    height: 100px;
    .img-group {
      .img {
        float: left;
        margin-left: 1%;
      }
      // .side-btn {
      //     float: left;
      //     margin-top: 5px;
      // }
    }
    .d-text {
      position: relative;
      width: 50%;
      text-align: center;
      margin-left: 28%;
      font-size: 20px;
      .p-text {
        text-align: center;
        color: #c23422;
        font-family: "Arial Rounded";
        float: left;
        font-weight: bold;
        margin-top: -5px;
      }
    }
    .d-text2 {
      margin-left: 26%;
      .p-text2 {
        text-align: center;
        color: #c23422;
        font-family: "Arial Rounded";
        float: left;
        font-weight: bold;
        margin-top: 10px;
        font-size: 20px;
      }
    }
    .t_pos1 {
      margin-left: 30%;
      .p-text2 {
        text-align: center;
        color: #c23422;
        font-family: "Arial Rounded";
        float: left;
        font-weight: bold;
        margin-top: 10px;
        font-size: 20px;
      }
    }
    .btn-content2 {
      float: right;
      margin-top: 12px;
      margin-left: 89%;
      position: absolute;
      text-align: center;
      padding: 0;
      .viapro {
        font-size: 15px;
        color: #c23422 !important;
        // text-decoration: none;
        font-weight: bold;
        // margin-left: 40%;
      }
    }
    .btn-content {
      margin-top: -10px;
      margin-left: 89%;
      position: absolute;
      text-align: center;
      padding: 0;
      // padding-left: 20px;
      .viapro {
        font-size: 15px;
        color: #c23422 !important;
        // text-decoration: none;
        font-weight: bold;
        // margin-left: 40%;
      }
      .btn {
        background-color: #c23422;
        font-weight: bold;
        text-align: center;
        float: right;
        height: 40px;
        width: 200px;
        .btn-icon {
          font-weight: bold;
          text-align: center;
          font-size: 14px;
          padding: 1px;
          .btn-span {
            padding-left: 8px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 900px) and (max-width: 1100px) {
  .g-content {
    text-align: center;
    position: relative;
    margin: auto;
    .content {
      margin-top: 50px;
      height: 100px;
      .img-group {
        .img {
          float: left;
        }
        // .side-btn {
        //     float: left;
        //     margin-top: 5px;
        // }
      }
      .d-text {
        position: relative;
        text-align: center;
        margin-left: 30%;
        .p-text {
          text-align: center;
          color: #c23422;
          font-family: "Arial Rounded";
          float: left;
          font-weight: bold;
          font-size: 15px;
        }
      }
      .d-text2 {
        position: relative;
        width: 55%;
        text-align: center;
        margin-left: 25%;
        margin-top: -10px;
        .p-text2 {
          text-align: center;
          color: #c23422;
          font-family: "Arial Rounded";
          float: left;
          font-weight: bold;
          font-size: 18px;
        }
      }
      .t_pos1 {
        position: relative;
        width: 55%;
        text-align: center;
        margin-left: 24%;
        margin-top: -10px;
        .p-text2 {
          text-align: center;
          color: #c23422;
          font-family: "Arial Rounded";
          float: left;
          font-weight: bold;
          font-size: 18px;
        }
      }
      .btn-content2 {
        float: right;
        margin-top: 18px;
        margin-left: 80%;
        position: absolute;
        text-align: center;
        padding: 0;
        .viapro {
          font-size: 15px;
          color: #c23422 !important;
          // text-decoration: none;
          font-weight: bold;
          // margin-left: 40%;
        }
      }
      .btn-content {
        margin-top: -7px;
        margin-left: 78%;
        position: absolute;
        text-align: center;
        padding: 0;
        padding-left: 23px;
        .viapro {
          font-size: 15px !important;
        }
        .btn {
          background-color: #c23422;
          font-weight: bold;
          text-align: center;
          height: 40px;
          width: 200px;
          .btn-icon {
            font-weight: bold;
            text-align: center;
            font-size: 14px;
            padding: 1px;
            .btn-span {
              padding-left: 8px;
            }
          }
        }
      }
    }
  }
}
</style>
